import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SpecialCardPage from './SpecialCardPage';
import MessageCardPage from './MessageCardPage';
import CardPage from './CardPage';
import ScrollToTop from './ScrollToTop';
function App() {
  useEffect(() => {
    if (window.Kakao && !window.Kakao.isInitialized()) {
      window.Kakao.init('77acaf5bebcc185da6e6be724bfe5adc'); // 자신의 JavaScript 키로 대체
    }
  }, []);
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<SpecialCardPage />} />
        <Route path="/message-card/:themeId" element={<MessageCardPage />} />
        <Route path="/card/:token" element={<CardPage />} />
        {/* 다른 라우트 추가 가능 */}
      </Routes>
    </Router>
  );
}

export default App;
