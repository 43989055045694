import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Confetti from './Confetti'; // Confetti 컴포넌트 가져오기
import axios from 'axios';
import MainBanners from './MainBanners';
import config from './config';


const CardPage = () => {
  const navigate = useNavigate();
  const { token } = useParams(); // URL 파라미터로 전달된 token
  const [nickname, setNickname] = useState('');
  const [sender, setSender] = useState('');
  const [comment, setComment] = useState('');
  const [galleryPhotos, setGalleryPhotos] = useState(Array(9).fill(null)); // 9개의 사진 리스트
  const [mainPhoto, setMainPhoto] = useState([]); // 메인 사진 리스트
  const [cardComment, setCardComment] = useState([]); // 메인 사진 리스트
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [cardID, setCardID] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // 로딩 상태 추가
  const [previewData, setPreviewData] = useState({}); // 미리보기 데이터 상태
  const [showConfetti, setShowConfetti] = useState(false); // 콘페티 효과 상태
  // 추가된 상태 변수 - 확대 미리보기 모달 상태
  const [isZoomModalOpen, setIsZoomModalOpen] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [loadedImagesCount, setLoadedImagesCount] = useState(0); // 로드된 이미지 개수

  const handleImageLoad = () => {
    setLoadedImagesCount((prevCount) => prevCount + 1);
  };
  // 확대 미리보기 모달 열기
  const handleZoomOpen = (photo) => {
    setSelectedPhoto(photo);
    setIsZoomModalOpen(true);
  };

  // 확대 미리보기 모달 닫기
  const handleZoomClose = () => {
    setIsZoomModalOpen(false);
    setSelectedPhoto(null);
  };

  useEffect(() => {
    if (loadedImagesCount === 2) {
      setIsLoading(false); // 모든 이미지가 로드되면 로딩 상태를 false로 설정
    }
  }, [loadedImagesCount]);

  // 서버에서 토큰 검증 및 카드 데이터를 받아오는 함수
  const CommentForm = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('card_id', cardID.toString());
      formData.append('nickname', nickname.toString());
      formData.append('comment', comment.toString());
      const response = await axios.post(`${config.API_URL}/card-comments/`, formData);
  
      // 서버로부터 받은 전체 코멘트 리스트로 상태 업데이트
      setCardComment(response.data); // response.data는 코멘트 리스트일 것으로 예상
      setNickname(''); // 닉네임 초기화
      setComment(''); // 메시지 초기화
      setIsLoading(false); // 로딩 완료
      
    } catch (error) {
      console.error('Error submitting comment:', error);
      setIsLoading(false); // 로딩 완료
      // 에러 처리 (필요 시)
    }
  };





  // 서버에서 토큰 검증 및 카드 데이터를 받아오는 함수
  const fetchCardData = async () => {
    try {
      const formData = new FormData();
      formData.append('token', token.toString());
      const response = await axios.post(`${config.API_URL}/verify-token`, formData);
      const data = response.data;

      console.log(data)
      // 서버에서 받은 데이터를 상태에 저장
      const card = data.card;
      const cardGallery = data.card_gallery;
      const theme = data.theme;
      const cardComment = data.card_comment;

      // 서버에서 받은 데이터를 상태에 저장
      setSender(card.sender);
      setGalleryPhotos(cardGallery.map((galleryItem) => `${config.API_URL}/${galleryItem.img_url}`));
      setCardComment(cardComment);
      setMainPhoto([`${config.API_URL}/${card.main_img_url}`]);
      setTitle(card.title);
      setContent(card.content);
      setCardID(card.id);
      setPreviewData({
        main_graphic_img_url: theme.main_graphic_img_url,
        gallery_background_img_url: theme.gallery_background_img_url, // 갤러리 배경 이미지 설정
        theme_img_url: theme.theme_img_url
      });
      setShowConfetti(true);
      setIsLoading(false); // 로딩 완료
    } catch (error) {
      console.error('Error verifying token or fetching card data:', error);
      setIsLoading(false); // 로딩 완료
      // 에러 처리 (필요 시)
    }
  };

  // 컴포넌트가 마운트될 때 토큰 검증 및 카드 데이터 가져오기
  useEffect(() => {
    if (token) {
      fetchCardData();
    }
  }, [token]);

  const handleGoBack = () => {
    navigate('/');
  };
  const handleGoURL = (url) => {
    console.log(url)
    window.open(url, '_blank');
  };


  return (
    <div
    id='topContainer'
  >
    <div id='bannerContainer'><MainBanners /></div>
    <div 
    id='mainFrame'>
      
      {isLoading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // 배경 투명도 설정
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000, // 다른 요소 위에 표시되도록 설정
          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              padding: '20px',
              borderRadius: '12px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // 모달 박스 쉐도우
            }}
          >
            <img
              src="/loading.gif" // 로딩 아이콘 이미지 경로
              alt="Loading"
              style={{ width: '50px', height: '50px', marginBottom: '10px' }}
            />
          </div>
        </div>
      )}


  {/* 확대 미리보기 모달 */}
  {isZoomModalOpen && (
            <div
              onClick={handleZoomClose} // 모달 클릭 시 닫기
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000,
                cursor: 'pointer'
            }}
          >
            <img src={selectedPhoto} alt="확대 사진" style={{ maxWidth: '90%', maxHeight: '90%', borderRadius: '8px' }} />
          </div>
        )}

        <div
          style={{

            top: '0px',
            backgroundColor: '#fff',
            padding: '0px 0px',
            
            width: '100%', // 모바일 화면 너비로 고정
            margin: '0 auto', // 중앙 정렬
            maxHeight: '99vh', // 모달 최대 높이 설정
            overflowY: 'auto', // y축 스크롤 활성화
            overflowX: 'hidden', // y축 스크롤 활성화
            scrollbarWidth: 'none', // Firefox에서 스크롤바 숨기기
            msOverflowStyle: 'none', // Internet Explorer에서 스크롤바 숨기기
          }}
        >
          <style>
            {`
                div::-webkit-scrollbar {
                display: none; /* 스크롤바 숨기기 */
                }
            `}
          </style>
          <div
            style={{
              backgroundColor: '#fff',
              width: '100%',
              borderRadius: '10px',
              position: 'relative',
              //paddingTop:'52px',
            }}
          >
                    <div 
                    id = 'header'
          style={{ 
            display: 'flex', 
            justifyContent: 'flex-start', 
            alignItems: 'center', 
            height: '51px', 
            borderBottom: '1px solid #e4e4e4', // 회색 바텀 보더 추가
            padding: '0 20px',
            position: 'fixed', // 헤더를 고정
            top: 0,
            left: 0,
            width: '100%',
            backgroundColor: '#fff', // 배경색 추가
            zIndex: 990, // 콘텐츠 위에 보이도록 z-index 설정
          }}
        >
          <img 
            id="margin70"
            src="/logo_2.png" 
            alt="로고" 
            style={{ cursor: 'pointer', width: '100px', height: '15px' }} 
            onClick={handleGoBack} // 로고 클릭 시 페이지 이동
          />
        </div>

            <div
            id = "padding0"

            style={{width: '100%', position: 'relative', paddingTop:'52px'}}>
            <div style={{width: '100%', position: 'relative'}}>
            <Confetti selectedTheme={previewData} showConfetti={showConfetti} />
              {/* main_graphic_img_url */}
              {previewData.main_graphic_img_url && (
                <div 

                style={{ display:'flex' }}>
                  <img
                    onLoad={handleImageLoad} // 이미지 로드 이벤트
                    onError={handleImageLoad} // 로드 오류 시에도 호출
                    src={`${config.API_URL}/${previewData.main_graphic_img_url}`}
                    alt="Main Graphic"
                    style={{ width: '100%', aspectRatio:'1/1.25' }}
                  />
                </div>
              )}

              {/* 갤러리 사진 9개 표시 */}
              <div 

                style={{ 
                  position: 'relative', // 자식 요소들의 절대 위치 지정 가능
                  marginBottom: '32px',
                  marginTop: '-0.5px',
                  //padding: '65px 40px 105px 40px',
                  aspectRatio: '1/1.25', // 이미지 비율 유지
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center' 
                }}
              >
                {/* 배경 이미지 */}
                <img 
                  onLoad={handleImageLoad} // 이미지 로드 이벤트
                  onError={handleImageLoad} // 로드 오류 시에도 호출
                  src={`${config.API_URL}/${previewData.gallery_background_img_url}`} 
                  alt="갤러리 배경" 
                  style={{ 
                    position: 'absolute', // 부모 요소에 대해 절대 위치 지정
                    top: 0, 
                    left: 0, 
                    width: '100%', 
                    objectFit: 'cover', // 이미지가 영역에 맞게 커버
                    zIndex: 1, // 다른 요소들 아래로 배치
                  }} 
                />

                {/* 그리드 박스 */}
                <div 
                  style={{ 
                    position: 'relative', // 배경 이미지 위에 오버레이
                    display: 'grid', 
                    gridTemplateColumns: 'repeat(3, 1fr)', 
                    gap: '10px', 
                    width: '65%', 
                    aspectRatio: '1/1', 
                    zIndex: 2, // 배경보다 위에 오도록 설정
                  }}
                >
                  {galleryPhotos.map((photo, index) => (
                    <div
                      key={index}
                      onClick={() => photo && handleZoomOpen(photo)} 
                      style={{
                        width: '100%',
                        cursor: 'pointer',
                        aspectRatio: '1/1',
                        backgroundColor: '#ffffff',
                        borderRadius: '6px',
                        color: '#7f7f7f',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '4px solid #ffffff',
                        fontFamily: 'sb_l',
                        margin: 'auto'
                      }}
                    >
                      {photo ? (
                        <img src={photo} alt={`사진 ${index + 1}`} style={{ width: '100%', aspectRatio: '1/1', objectFit: 'cover' }} />
                      ) : (
                        <span style={{ color: '#ccc' }}></span> // 업로드 안된 경우 회색 박스
                      )}
                    </div>
                  ))}
                </div>
              </div>
              </div>


              {/* 제목 표시 */}
              <div style={{ margin: '0px' }}>
                <div
                  style={{
                    textAlign: 'center',

                    fontSize: '20px',
                    padding: '12px 20px',
                    fontFamily: 'sb_m',
                    lineHeight: '32px',
                    wordBreak: 'break-word',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {title || '제목이 없습니다.'}
                </div>
              </div>

              <div style={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)' }}>
                {mainPhoto.map((photo, index) => (
                  <div
                    key={index}
                    style={{
                      position: 'relative',
                      width: '100%',
                      aspectRatio: '1/1',
                      backgroundColor: photo ? 'transparent' : '#f0f0f0', // 업로드 안된 부분은 회색 배경
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '8px',
                      border: '1px solid #ccc',
                    }}
                  >
                    {photo ? (
                      <img
                        src={photo}
                        alt={`사진 ${index + 1}`}
                        style={{ width: '100%', aspectRatio: '1/1', objectFit: 'cover' }}
                      />
                    ) : (
                      <span style={{ color: '#ccc' }}></span> // 업로드 안된 경우 회색 박스
                    )}
                  </div>
                ))}
              </div>

              {/* 제목 표시 */}
              <div style={{ margin: '40px 0px 32px 0px' }}>
                <div
                  style={{
                    textAlign: 'left',
                    padding: '8px 20px',
                    fontSize: '16px',
                    lineHeight: '150%',
                    fontFamily: 'pretendard',
                    wordBreak: 'break-word',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {content || '내용이 없습니다.'}
                </div>
              </div>


      {/* 링크 복사 아이콘 */}
      <div style={{ textAlign: 'center', marginBottom: '48px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px' }}>
  {/* URL 복사 아이콘 */}
  <img
    src="/icon_link.png"
    alt="Copy Link"
    onClick={() => {
      const urlToCopy = `${window.location.href}`;
      const textArea = document.createElement('textarea');
      textArea.value = urlToCopy;
      document.body.appendChild(textArea);
      textArea.select();
      textArea.setSelectionRange(0, 99999);
      document.execCommand('copy');
      document.body.removeChild(textArea);

      console.log('URL이 성공적으로 클립보드에 복사되었습니다!');
      alert('URL이 복사되었어요.');
    }}
    style={{ cursor: 'pointer', width: '24px', height: '24px' }}
  />

  {/* 카카오 공유 아이콘 */}
  <img
    src="/ic_kakao.png"
    alt="Kakao Share"
    onClick={() => {
      if (window.Kakao && window.Kakao.Share) {
        window.Kakao.Share.sendDefault({
          objectType: 'feed',
          content: {
            title: sender + '님으로부터 편지가 도착했습니다.',
            description: title,
            imageUrl: mainPhoto[0],
            link: {
              mobileWebUrl: `${window.location.href}`,
              webUrl: `${window.location.href}`,
            },
          },
          /*itemContent: {
            profileText: 'Kakao',
            profileImageUrl: 'https://mud-kage.kakao.com/dn/Q2iNx/btqgeRgV54P/VLdBs9cvyn8BJXB3o7N8UK/kakaolink40_original.png',
            titleImageUrl: 'https://mud-kage.kakao.com/dn/Q2iNx/btqgeRgV54P/VLdBs9cvyn8BJXB3o7N8UK/kakaolink40_original.png',
            titleImageText: 'Cheese cake',
            titleImageCategory: 'Cake',
            items: [
              { item: 'Cake1', itemOp: '1000원' },
              { item: 'Cake2', itemOp: '2000원' },
              { item: 'Cake3', itemOp: '3000원' },
              { item: 'Cake4', itemOp: '4000원' },
              { item: 'Cake5', itemOp: '5000원' },
            ],
            sum: '총 결제금액',
            sumOp: '15000원',
          },*/
          buttons: [
            {
              title: '편지 보러가기',
              link: {
                mobileWebUrl: `${window.location.href}`,
                webUrl: `${window.location.href}`,
              },
            },
          ],
        });
      } else {
        alert('Kakao SDK가 초기화되지 않았습니다.');
      }
    }}
    style={{ cursor: 'pointer', width: '17px', height: '16px' }}
  />
</div>



      <div style={{backgroundColor:'#1e1e1e', padding:'28px 0px 40px 0px'}}>
        {/* 로고 */}
        <div style={{ textAlign: 'center', height: '48px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src="/logo.png" alt="Logo" style={{ width: '169px', height: '28px' }} />
        </div>

        <div style={{ padding: '8px 20px'}}>
          {/* 레시피 카드 1 */}
          <div style={{ backgroundColor: '#ffffff', padding: '16px 24px 28px 24px', borderRadius: '12px', marginBottom: '20px' }}>
            <div style={{ height: '40px', fontFamily: 'pretendard_b', fontSize:'16px', lineHeight:'40px', display:'flex', alignItems:'center' }}>한우 로스구이 정식 <span style ={{fontFamily:'pretendard_m', fontSize:'12px', color: '#B79056', marginLeft: '4px'}}>Recipe.</span></div>
            <img src="/img_recipe.png" alt="Recipe" style={{ width: '100%', marginBottom: '12px', borderRadius:'8px' }} />
            <div style={{fontFamily: 'pretendard_m', fontSize:'14px', lineHeight:'150%',color:'#000000'}}>고기를 부드럽게 채소를 더 맛있게</div>
            <div style={{fontFamily: 'pretendard', fontSize:'14px', lineHeight:'150%',color:'#777777', marginBottom:'8px'}}>로스용 고기를 올리브오일과 스테이크시즈닝에 버무려 냉장고에 두었다가 구우면 한결 부드러운 육질의 구이를 맛볼 수 있어요.</div>
            <button 
            onClick={() => handleGoURL('https://youtube.com/@sopecial?si=xTw4OU9IduVIRqae')}
            style={{ width: '100%', height: '40px', backgroundColor: '#f5f5f5', color: '#1e1e1e', border: 'none', borderRadius: '8px', fontFamily:'pretendard_sb', fontSize:'12px',lineHeight:'20px', cursor:'pointer' }}>레시피 전체보기</button>
          </div>
        </div>

        <div 
        onClick={() => handleGoURL('https://www.sopecial.co.kr/goods/goods_list.php?cateCd=006004')}
        style={{padding: '8px 20px',  cursor:'pointer'}}>
          {/* 레시피 카드 1 */}
          <div style={{ backgroundColor: '#ffffff', padding: '16px 24px 28px 24px', borderRadius: '12px', marginBottom: '20px' }}>
            <div style={{ height: '40px', fontFamily: 'pretendard_b', fontSize:'16px', lineHeight:'40px', display:'flex', alignItems:'center' }}>소페셜 선물세트 <span style ={{fontFamily:'pretendard_m', fontSize:'12px', color: '#B79056', marginLeft: '4px'}}>Gift set</span></div>
            <img src="/img_set.png" alt="set" style={{ width: '100%', marginBottom: '12px', borderRadius:'8px' }} />
            <div style={{fontFamily: 'pretendard', fontSize:'14px', lineHeight:'150%',color:'#777777'}}>감동의 맛을 선물해 보세요</div>
            <div style={{fontFamily: 'pretendard_b', fontSize:'16px', lineHeight:'150%',color:'#000000'}}>소고기의 정수를 담은 특별한 한우</div>
          </div>
        </div>

        {/* 배너 이미지 1 */}
        <div 
        onClick={() => handleGoURL('https://sopecial.co.kr/main/index.php')}
        style={{ textAlign: 'center', padding: '8px 20px',  cursor:'pointer' }}>
          <img src="/banner1.png" alt="Banner 1" style={{ width: '100%', borderRadius: '12px' }} />
        </div>

        {/* 배너 이미지 2 */}
        <div 
        onClick={() => handleGoURL('https://sopecial.com')}
        style={{ textAlign: 'center', padding: '8px 20px', cursor:'pointer' }}>
          <img src="/banner2.png" alt="Banner 2" style={{ width: '100%', borderRadius: '12px' }} />
        </div>
      </div>


              {/* 하얀색 배경 - 메세지 남기기 및 댓글 */}
              <div style={{ backgroundColor: '#fff', padding: '32px 20px 0px 20px', textAlign: 'center' }}>
                <div
                  style={{
                    textAlign: 'center',
                    fontFamily: 'pretendard',
                    fontSize: '20px',
                    lineHeight: '33px',
                    padding: '8px 0px',
                  }}
                >
                  메세지 남기기
                </div>
                <input type="text" 
                value={nickname}
                onChange={(e) => setNickname(e.target.value)}
                placeholder="닉네임" style={{             resize: 'none', 
            padding: '16px',
            overflow: 'hidden',
            border: '1px solid #E5E4E3',
            width: '100%',  
            boxSizing: 'border-box',
            fontSize: '16px',
            color: '#1e1e1e',
            fontFamily: 'pretendard',
            lineHeight: '150%',
            borderRadius: '8px', 
            marginBottom: '14px', marginTop: '8px'}} />

<textarea 
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
placeholder="메세지를 입력해 주세요.&#10;등록된 메세지는 삭제가 불가해요." style={{ 

resize: 'none', 
padding: '16px',
overflow: 'hidden',
border: '1px solid #E5E4E3',
width: '100%',  
boxSizing: 'border-box',
fontSize: '16px',
color: '#1e1e1e',
fontFamily: 'pretendard',
lineHeight: '150%',
borderRadius: '8px',
marginBottom: '8px'
}}></textarea>

                <div style={{ height: '32px', padding: '8px 0px', marginBottom: '16px' }}>
                  <div
                    onClick={CommentForm}
                    style={{
                      padding: '4px 12px',
                      backgroundColor: '#EFE2B6',
                      color: '#000000',
                      border: 'none',
                      borderRadius: '8px',
                      cursor: 'pointer',
                      float: 'right',
                      fontSize: '16px',
                      lineHeight: '150%',
                      fontFamily: 'pretendard_m',
                    }}
                  >
                    등록
                  </div>
                </div>
              </div>

              <hr style={{ border: '1px solid #c4c4c4', margin: '6px auto', width: '85%' }} />
              <div style={{ marginBottom: '12px' }}></div>



              <div style={{ padding: '8px 20px' }}>
                {cardComment.map((comment, index) => (
                  <div
                    key={index}
                    style={{
                      backgroundColor: '#F5F5F5',
                      padding: '16px 20px',
                      borderRadius: '12px',
                      marginBottom: '12px',
                    }}
                  >
                    <div
                      style={{
                        fontSize: '14px',
                        marginBottom: '2px',
                        fontFamily: 'pretendard_sb',
                        lineHeight: '150%',
                        color: '#282A2E',
                      }}
                    >
                      {comment.nickname}
                    </div>
                    <div
                      style={{
                        fontSize: '14px',
                        fontFamily: 'pretendard_sb',
                        lineHeight: '150%',
                        wordBreak: 'break-word',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-line',
                        color: '#7D7E7F',
                      }}
                    >
                      {comment.comment}
                    </div>
                  </div>
                ))}
              </div>




              <div style={{ backgroundColor: '#ffffff', marginBottom: '40px' }}></div>
            </div>
          </div>
        </div>

    </div>
    </div>
  );
};

export default CardPage;
