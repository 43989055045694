import React from 'react';
import { useNavigate } from 'react-router-dom';

const MainBanners = () => {
  const navigate = useNavigate();

  const banners = [
    {
      src: '/main_banner_1.png',
      url: '/',
      style: {width: '274px', cursor: 'pointer', marginBottom: '30px'}
    },
    {
      src: '/main_banner_2.png',
      url: 'https://sopecial.co.kr/',
      style: {width: '92.6px', cursor: 'pointer', marginBottom: '45px'}
    },
    {
      src: '/main_banner_3.png',
      url: 'https://sopecial.co.kr/',
      style: {width: '378px', cursor: 'pointer', marginLeft:'-9px'}
    },
    {
      src: '/main_banner_4.png',
      url: 'https://sopecial.co.kr/',
      style: {width: '360px', cursor: 'pointer', marginBottom: '14px'}
    },
    {
        src: '/main_banner_5.png',
        url: 'https://sopecial.com',
        style: {width: '360px', cursor: 'pointer'}
      },
  ];

  const handleBannerClick = (index, url) => {
    if (index === 0 || index === 1)
        return
    // URL로 페이지 이동
    if(index === 2)
        navigate('/');
    else
        window.open(url, '_blank');

    // navigate(url); // 내부 라우팅으로 이동할 경우 사용
  };

  return (
    <div id="main_banners" style={{ width: '360px', display: 'none', flexDirection: 'column', gap: '0px' }}>
      {banners.map((banner, index) => (
        <img
          key={index}
          src={banner.src}
          alt={`배너 ${index + 1}`}
          onClick={() => handleBannerClick(index, banner.url)}
          style={banner.style}
        />
      ))}
    </div>
  );
};

export default MainBanners;
