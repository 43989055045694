import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // axios를 사용하여 DB에서 데이터 가져오기
import config from './config';  // config.js 파일 가져오기
import Confetti from './Confetti'; // Confetti 컴포넌트 가져오기
import MainBanners from './MainBanners';

const SpecialCardPage = () => {
  const navigate = useNavigate(); // 페이지 이동을 위한 훅
  const [themes, setThemes] = useState([]); // DB에서 불러온 테마들
  const [selectedTheme, setSelectedTheme] = useState(null); // 선택된 테마 상태
  const [showConfetti, setShowConfetti] = useState(true); // 콘페티 효과 상태
  const scrollRef = useRef(null); // 스크롤을 제어하기 위한 ref

  const isDragging = useRef(false); // 드래그 중인지 확인하는 변수
  const startX = useRef(0); // 마우스를 누른 시점의 X 좌표
  const scrollLeft = useRef(0); // 스크롤 시작 위치
  const [isLoading, setIsLoading] = useState(true); // 로딩 상태 추가
  const [loadedImagesCount, setLoadedImagesCount] = useState(0); // 로드된 이미지 개수



  // 모든 이미지가 로드되었는지 확인
  useEffect(() => {
    if (loadedImagesCount === 2) {
      setIsLoading(false); // 모든 이미지가 로드되면 로딩 상태를 false로 설정
    }
  }, [loadedImagesCount]);

  // DB에서 테마 데이터 가져오기
  useEffect(() => {
    axios.get(`${config.API_URL}/themes`) // 프로토콜 추가
      .then((response) => {
        setThemes(response.data); // 테마 데이터를 상태로 저장

        // 첫 번째 테마를 기본 선택으로 설정
        if (response.data.length > 0) {
          setSelectedTheme(response.data[0]);
        }
      })
      .catch((error) => {
        console.error('Error fetching themes:', error);
      });
  }, []);
  const handleImageLoad = () => {
    setLoadedImagesCount((prevCount) => prevCount + 1);
  };
  // 테마 선택 처리
  const handleThemeSelect = (theme) => {
    setSelectedTheme(theme);
    setShowConfetti(true);
  };

  // 선택된 테마 예시 이미지를 보여주는 함수
  const renderThemePreview = () => {
    if (!selectedTheme) {
      return <div style={{ backgroundColor: '#f5f5f5' }}></div>;
    }

    return (
      <div style={{backgroundColor: '#ffffff', position: 'relative' }}>
        <img
          onLoad={handleImageLoad} // 이미지 로드 이벤트
          onError={handleImageLoad} // 로드 오류 시에도 호출
          src={`${config.API_URL}/` + selectedTheme.main_graphic_img_url}
          alt={`${selectedTheme.name} 테마 이미지`}
          style={{ width: '100%', display: 'block', height: '100%', objectFit: 'cover', borderTopLeftRadius: "16px", borderTopRightRadius: "16px",  }}
        />
      </div>
    );
  };

  const renderGalleryPreview = () => {
    if (!selectedTheme) {
      return <div style={{  backgroundColor: '#ffffff' }}></div>;
    }

    return (
      <div 
        style={{ 
          position: 'relative',
          marginTop:'-0.5px',
          marginBottom: '32px',
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          display: 'block',
        }}
      >
        {/* 배경 이미지 */}
        <img 
          onLoad={handleImageLoad} // 이미지 로드 이벤트
          onError={handleImageLoad} // 로드 오류 시에도 호출
          src={`${config.API_URL}/${selectedTheme.gallery_background_img_url}`} 
          alt="갤러리 배경" 
          style={{ 
            width: '100%', 
            aspectRatio: '1/1.25',
            objectFit: 'cover',
            zIndex: 1, 
          }} 
        />

        {/* 그리드 박스 */}
        <div 
          style={{ 
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'grid', 
            gridTemplateColumns: 'repeat(3, 1fr)', 
            gridGap: '10px', 
            zIndex: 2,
          }}
        >
          {Array.from({ length: 9 }).map((_, index) => (
            <div
              key={index}
              style={{
                height: '65px',
                width: '65px',
                backgroundColor: '#ffffff',
                borderRadius: '5.33px',
                color: '#7f7f7f',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '3.56px solid #ffffff',
                fontFamily: 'sb_l',
              }}
            >
              사진
            </div>
          ))}
        </div>
      </div>
    );
  };

  // 페이지 이동 핸들러
  const handleGoBack = () => {
    navigate('/');
  };

  // 선택 버튼을 눌렀을 때, 선택한 테마의 id를 가지고 MessageCardPage로 이동
  const handleSelectTheme = () => {
    if (selectedTheme) {
      navigate(`/message-card/${selectedTheme.id}`);
    }
  };

  // 마우스 이벤트 핸들러들
  const handleMouseDown = (e) => {
    isDragging.current = true;
    startX.current = e.pageX - scrollRef.current.offsetLeft;
    scrollLeft.current = scrollRef.current.scrollLeft;
  };

  const handleMouseMove = (e) => {
    if (!isDragging.current) return;
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX.current) * 1.5; // 스크롤 속도 조절
    scrollRef.current.scrollLeft = scrollLeft.current - walk;
  };

  const handleMouseUp = () => {
    isDragging.current = false;
  };
  const handleGoURL = (url) => {
    console.log(url)
    window.open(url, '_blank');
  };
  return (
    <div
    id='topContainer'
  >
      <div
      id='bannerContainer'

      ><MainBanners /></div>
    <div id='mainFrame'
    >
            {isLoading && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // 배경 투명도 설정
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1000, // 다른 요소 위에 표시되도록 설정
            }}
          >
            <div
              style={{
                backgroundColor: '#fff',
                padding: '20px',
                borderRadius: '12px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // 모달 박스 쉐도우
              }}
            >
              <img
                src="/loading.gif" // 로딩 아이콘 이미지 경로
                alt="Loading"
                style={{ width: '50px', height: '50px', marginBottom: '10px' }}
              />
            </div>
          </div>
        )}

    <div
      style={{
        width: '100%',
        minHeight: '100vh',
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: '100%', // 모바일 화면 크기로 고정
          minHeight: '100vh',
          backgroundColor: '#fff',
          padding: '0px 20px 70px 20px',
          overflow: 'hidden', // 내용이 넘치지 않도록
        }}
      >
        {/* 고정 헤더 영역 - 로고 왼쪽 배치, 바텀 보더 추가 */}
        <div 
          id = 'header'
          style={{ 
            display: 'flex', 
            justifyContent: 'flex-start', 
            alignItems: 'center', 
            height: '51px', 
            borderBottom: '1px solid #e4e4e4', // 회색 바텀 보더 추가
            padding: '0 20px',
            position: 'fixed', // 헤더를 고정
            top: 0,
            left: 0,
            width: '100%',
            backgroundColor: '#fff', // 배경색 추가
            zIndex: 1000, // 콘텐츠 위에 보이도록 z-index 설정
          }}
        >
          <img 
            src="/logo_2.png" 
            alt="로고" 
            style={{ cursor: 'pointer', width: '100px', height: '15px' }} 
            onClick={handleGoBack} // 로고 클릭 시 페이지 이동
          />
        </div>

        {/* 헤더 하단에 패딩 추가, 헤더 영역을 덮지 않도록 */}
        <div 
        id = "padding15"
        style={{ paddingTop: '58px' }}>
          <p
            style={{
              fontSize: '24px',
              lineHeight: '40px',
              height: '80px',
              padding: '8px 0px',
              margin: '0px 0px 24px 0px',
              fontFamily: 'pretendard_b',
            }}
          >
            스페셜 카드의
            <br />
            테마를 선택해 주세요.
          </p>

          {/* 테마 버튼들을 가로 스크롤로 */}
          <div
            ref={scrollRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp} // 마우스가 영역을 벗어났을 때 드래그 해제
            style={{
              display: 'flex',
              overflowX: 'scroll',
              marginBottom: '12px',
              paddingBottom: '10px',
              paddingTop: '10px',
              scrollbarWidth: 'none', // Firefox에서 스크롤바 숨기기
              msOverflowStyle: 'none', // Internet Explorer에서 스크롤바 숨기기
              cursor: 'grab', // 드래그가 가능함을 표시
              userSelect: 'none', // 드래그 중 텍스트 선택 방지
            }}
          >
            {/* 웹킷 브라우저에서 스크롤바를 숨기기 위한 추가 CSS */}
            <style>
              {`
                div::-webkit-scrollbar {
                  display: none; /* 스크롤바 숨기기 */
                }
              `}
            </style>
            {themes.map((theme) => (
              <div
                key={theme.id}
                onClick={() => handleThemeSelect(theme)}
                style={{
                  padding: '8px 16px',
                  marginRight: '10px',
                  borderRadius: '20px',
                  height: '24px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: selectedTheme && selectedTheme.id === theme.id ? '#EFE2B6' : '#f4f4f4',
                  color: '#000000',
                  cursor: 'pointer',
                  fontFamily: 'pretendard_m',
                  fontSize: '16px',
                  whiteSpace: 'nowrap',
                  userSelect: 'none', // 각 버튼에서도 텍스트 선택 방지
                }}
              >
                {theme.name}
              </div>
            ))}
          </div>

          {/* 선택된 테마에 대한 예시 이미지 */}
          <div style={{width:'100%', position: 'relative'}}>
            {/* Confetti 컴포넌트 호출 */}
            <Confetti selectedTheme={selectedTheme} showConfetti={showConfetti} />

            {/* main_graphic_img 부분 */}
            {renderThemePreview()}

            {/* gallery_background_img 부분 */}
            {renderGalleryPreview()}
          </div>

          {/* 선택 버튼 */}
          <button
            onClick={handleSelectTheme}
            style={{
              width: '100%',
              padding: '10px',
              height: '56px',
              backgroundColor: '#1e1e1e',
              color: '#EFE2B6',
              border: 'none',
              borderRadius: '8px',
              cursor: 'pointer',
              fontFamily: 'pretendard_b',
              fontSize: '16px',
            }}
            disabled={!selectedTheme} // 테마 선택 안 하면 비활성화
          >
            선택
          </button>
        </div>
      </div>
    </div>
    </div>
    </div>
  );
};

export default SpecialCardPage;
