import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Confetti from './Confetti'; // Confetti 컴포넌트 가져오기
import axios from 'axios';
import MainBanners from './MainBanners';
import config from './config';

const MessageCardPage = () => {
  const navigate = useNavigate();
  const { themeId } = useParams(); // URL 파라미터로 전달된 테마 id


  // 상태 변수 정의
  const [sender, setSender] = useState('');
  const [galleryPhotos, setGalleryPhotos] = useState(Array(9).fill(null)); // 9개의 사진 리스트
  const [galleryPhotosFile, setGalleryPhotosFile] = useState(Array(9).fill(null)); // 9개의 사진 리스트
  const [mainPhoto, setMainPhoto] = useState(Array(1).fill(null)); // 메인 사진 리스트
  const [mainPhotoFile, setMainPhotoFile] = useState(Array(1).fill(null)); // 메인 사진 리스트
  const [uploadedMainPhotoURL, setUploadedMainPhotoURL] = useState(''); // 메인 사진 리스트
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [isAiModalOpen, setIsAiModalOpen] = useState(false); // AI 모달 상태
  const [isCardCompleteModalOpen, setIsCardCompleteModalOpen] = useState(false); // AI 모달 상태
  const [aiRecipient, setAiRecipient] = useState(null);
  const [aiTone, setAiTone] = useState(null);
  const [cardID, setCardID] = useState(null);
  const [token, setToken] = useState(null);
  const [aiCustomContent, setAiCustomContent] = useState('');
  const [isLoading, setIsLoading] = useState(false); // 로딩 상태 추가
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false); // 미리보기 모달 상태
  const [previewData, setPreviewData] = useState({}); // 미리보기 데이터 상태
  const [isAiModalClosing, setIsAiModalClosing] = useState(false); // 애니메이션 제어
  const [isPreviewModalClosing, setIsPreviewModalClosing] = useState(false); // 애니메이션 제어
  const [isCardCompleteModalClosing, setCardCompleteModalClosing] = useState(false); // 애니메이션 제어
  const [showConfetti, setShowConfetti] = useState(false); // 콘페티 효과 상태
  const [loadedImagesCount, setLoadedImagesCount] = useState(0); // 로드된 이미지 개수


  // 추가된 상태 변수 - 확대 미리보기 모달 상태
  const [isZoomModalOpen, setIsZoomModalOpen] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);


  useEffect(() => {
    if (loadedImagesCount === 2) {
      setIsLoading(false); // 모든 이미지가 로드되면 로딩 상태를 false로 설정
    }
  }, [loadedImagesCount]);

  const handleImageLoad = () => {
    setLoadedImagesCount((prevCount) => prevCount + 1);
  };
  
  // 확대 미리보기 모달 열기
  const handleZoomOpen = (photo) => {
    setSelectedPhoto(photo);
    setIsZoomModalOpen(true);
  };

  // 확대 미리보기 모달 닫기
  const handleZoomClose = () => {
    setIsZoomModalOpen(false);
    setSelectedPhoto(null);
  };


  // AI 모달 관련 상태 관리
  const handleOpenAiModal = () => setIsAiModalOpen(true);
  const handleCloseAiModal = () => {
        setIsAiModalClosing(true);
        setTimeout(() => {
          setIsAiModalOpen(false);
          setIsAiModalClosing(false);
        }, 350); // 애니메이션 시간과 동일하게 설정
  };

  const handleOpenCardCompleteModal = () => setIsCardCompleteModalOpen(true);
  const handleCloseCardCompleteModal = () => {
    setCardCompleteModalClosing(true);
    setTimeout(() => {
      setIsCardCompleteModalOpen(false);
      setCardCompleteModalClosing(false);
    }, 350); // 애니메이션 시간과 동일하게 설정
};


const handleGoURL = (url) => {
  console.log(url)
  window.open(url, '_blank');
};

  const handleCardSubmit = async () => {
    const formData = new FormData();
  
    // FormData에 데이터를 추가
    formData.append('sender', sender.toString()); // user_id를 formData에 추가
    formData.append('theme_id', themeId.toString()); // theme_id를 formData에 추가
    formData.append('title', title.toString()); // 제목을 formData에 추가
    formData.append('content', content.toString()); // 본문을 formData에 추가
  
    // 메인 이미지 추가 (있을 경우)
    if (mainPhotoFile[0]) {
      formData.append('main_img', mainPhotoFile[0]);
    }
  
    
    // 갤러리 이미지 추가 (9개의 이미지를 각각 추가)
    galleryPhotosFile.forEach((photo, index) => {
      if (photo) {
        formData.append('gallery_imgs', photo); // 파일 배열을 formData에 추가
      }
    });

    setIsLoading(true); // 로딩 상태 시작
    //alert(`${config.API_URL}/cards`)
    try {
      // FormData를 사용하여 POST 요청 전송
      const response = await axios.post(`${config.API_URL}/cards`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    
      // 성공 시 처리 (예: 성공 메시지, 페이지 이동 등)
      console.log('카드가 성공적으로 저장되었습니다:', response.data);
      setCardID(response.data.card_id);
      setToken(response.data.token);
      setUploadedMainPhotoURL(response.data.main_img_url)
      handleOpenCardCompleteModal();
    
    } catch (error) {
      if (error.response && error.response.status === 413) {
        // 413 에러 발생 시 사용자에게 경고 메시지
        alert("사진 전체 용량은 100MB를 넘을 수 없습니다.");
      } else {
        // 그 외의 에러 처리
        alert("오류가 발생했습니다. 다시 시도해 주세요.");
        console.error('Error sending card data:', error);
      }
    } finally {
      setIsLoading(false); // 로딩 상태 종료
    }
    
  };
  

  // AI 작성 요청
  const handleAiSubmit = async () => {
    const formData = new FormData();
  
    // FormData에 데이터를 추가
    formData.append('to', aiRecipient);
    formData.append('atmosphere', aiTone);
    formData.append('concept', aiCustomContent);
    
    setIsLoading(true); // 로딩 상태 시작
  
    try {
      // FormData를 사용하여 POST 요청 전송
      const response = await axios.post(`${config.API_URL}/card-ai-histories`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      // API 호출 후 title, content 값을 업데이트
      setTitle(response.data.output_title);
      setContent(response.data.output_content);
      setTimeout(() => {
        const textarea = document.querySelector('#content'); // 해당 textarea를 선택
        handleInput({ target: textarea }); // handleInput을 직접 호출
      }, 0);
      handleCloseAiModal();
    } catch (error) {
      console.error('Error sending form data:', error);
    } finally {
      setIsLoading(false); // 로딩 상태 종료
    }
  };


  useEffect(() => {
    const textarea = document.getElementById('content');
    const observer = new MutationObserver(() => {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    });
  
    observer.observe(textarea, {
      characterData: true,
      subtree: true,
      childList: true,
    });
  
    return () => observer.disconnect();
  }, []);

  const handleInput = (e) => {
    const textarea = e.target;
    //textarea.style.height = 'auto'; // 높이를 초기화
    textarea.scrollTop = 0;

    //const lineHeight = parseFloat(window.getComputedStyle(textarea).lineHeight);
    //const lines = textarea.value.split('\n').length;
    //const newHeight = lineHeight * lines;
    
    //const newHeight = textarea.scrollTop + textarea.clientHeight;
    //console.log(textarea.clientHeight)
    //textarea.style.height = `${newHeight}px`; // 스크롤 높이에 따라 높이 조정
  };

  // 사진 첨부 버튼 클릭 시
// 사진 첨부 버튼 클릭 시
// 사진 첨부 버튼 클릭 시
const handleAddPhoto = (index) => {
  const fileInput = document.createElement('input');
  fileInput.type = 'file';
  fileInput.accept = 'image/*';
  fileInput.multiple = true; // 여러 장 업로드 가능하도록 설정
  document.body.appendChild(fileInput);
  fileInput.style.display = 'none';


  fileInput.onchange = (event) => {

    const files = event.target.files;
    
    if (files.length > 1) {
      // 여러 장을 선택했을 경우
      const newPhotos = Array(9).fill(null); // 기존 이미지 초기화
      const newPhotosFile = Array(9).fill(null);
      
      // 선택한 이미지 수와 배열 최대 크기(9개) 중 작은 값을 설정
      const limit = Math.min(files.length, 9);
      
      for (let i = 0; i < limit; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onload = () => {

          newPhotos[i] = reader.result;
          setGalleryPhotos([...newPhotos]);
        };
        reader.readAsDataURL(file);

        newPhotosFile[i] = file; // 파일 객체 저장
      }
      setGalleryPhotosFile([...newPhotosFile]);
    } else if (files.length === 1) {
      // 한 장을 선택했을 경우 기존 방식으로 추가
      const file = files[0];
      const reader = new FileReader();
      reader.onload = () => {
        const newPhotos = [...galleryPhotos];
        newPhotos[index] = reader.result; // base64 이미지 저장

        setGalleryPhotos(newPhotos);
      };
      reader.readAsDataURL(file);

      const newPhotosFile = [...galleryPhotosFile];
      newPhotosFile[index] = file; // 파일 객체 저장
      setGalleryPhotosFile(newPhotosFile);
    }
    document.body.removeChild(fileInput)
  };

  fileInput.click();
};

  // 메인 사진 첨부 버튼 클릭 시
  const handleAddMainPhoto = (index) => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    document.body.appendChild(fileInput);
    fileInput.style.display = 'none';
  
    fileInput.onchange = (event) => {
      const file = event.target.files[0];
  
      if (file) {
        // Base64 이미지를 읽어 들이기 위한 FileReader 사용
        const reader = new FileReader();
        reader.onload = () => {
          const newPhotos = [...mainPhoto];
          newPhotos[index] = reader.result; // base64 데이터로 이미지 미리보기 저장
          setMainPhoto(newPhotos);
        };
        reader.readAsDataURL(file);
  
        // 파일 객체를 그대로 저장
        const newPhotosFile = [...mainPhotoFile];
        newPhotosFile[index] = file; // 파일 객체 저장
        setMainPhotoFile(newPhotosFile);
      }
      document.body.removeChild(fileInput);
    };
  
    fileInput.click();
  };
  


  const handleGoBack = () => {
    navigate('/');
  };
  // 미리보기 버튼 클릭 시 데이터 가져오기
  const handleOpenPreviewModal = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${config.API_URL}/themes/${themeId}`);
      const { main_graphic_img_url, theme_img_url, gallery_background_img_url } = response.data;
      setPreviewData({ main_graphic_img_url, theme_img_url, gallery_background_img_url });
      setIsPreviewModalOpen(true);
      setShowConfetti(true);
    } catch (error) {
      alert('미리보기 데이터를 불러오는 중 오류가 발생했습니다.');
    }
  };
  const handleClosePreviewModal = () => {
    setIsPreviewModalClosing(true);
    setTimeout(() => {
      setIsPreviewModalOpen(false);
      setIsPreviewModalClosing(false);
    }, 350); // 애니메이션 시간과 동일하게 설정
  };

  return (
    <div
    id='topContainer'
  >
    <div id='bannerContainer'><MainBanners /></div>
    <div id='mainFrame'>
    <div style={{ 
      width: '100%', 
      minHeight: '100vh', 
      backgroundColor: '#fff', 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center' 
    }}>
      <div style={{ 
        width: '100%', // 모바일 화면 크기로 고정 320으로바꿔
        minHeight: '100vh', 
        backgroundColor: '#fff', 
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', 
        padding: '0px 20px 20px 20px',
        overflow: 'hidden' // 내용이 넘치지 않도록
      }}>
        {/* 상단 뒤로가기 버튼 */}
        <div 
        id = 'header'
          style={{ 
            display: 'flex', 
            justifyContent: 'flex-start', 
            alignItems: 'center', 
            height: '51px', 
            borderBottom: '1px solid #e4e4e4', // 회색 바텀 보더 추가
            padding: '0 20px',
            position: 'fixed', // 헤더를 고정
            top: 0,
            left: 0,
            width: '100%',
            backgroundColor: '#fff', // 배경색 추가
            zIndex: 990, // 콘텐츠 위에 보이도록 z-index 설정
          }}
        >
          <img 
            src="/logo_2.png" 
            alt="로고" 
            style={{ cursor: 'pointer', width: '100px', height: '15px' }} 
            onClick={handleGoBack} // 로고 클릭 시 페이지 이동
          />
        </div>

        <div 
        id = "padding15"
        style={{ lineHeight: '40px', marginBottom: '24px', height: '80px', padding: '58px 0px 8px 0px', fontFamily: 'pretendard_b', fontSize: '24px'}}>메세지 카드의<br/>내용을 작성해주세요.</div>
        {/* 보내는 사람 입력 */}
        <div style={{height: '30px', lineHeight: '150%', padding: '10px 0px',fontFamily: 'pretendard_b', fontSize: '20px'}}>보내는 사람</div>
        <div style={{ textAlign: 'center', height: '56px', padding: '8px 0px', marginBottom:'20px' }}>
          <input
            type="text"
            value={sender}
            onChange={(e) => setSender(e.target.value)}
            placeholder="메세지카드에 표시될 이름을 작성해주세요."
            style={{
              border: '1px solid #E5E4E3',
              width: '100%', 
              boxSizing: 'border-box',
              height: '52px', 
              fontSize: '16px',
              color: '#1e1e1e',
              fontFamily: 'pretendard',
              padding: '0px 16px',
              lineHeight: '150%',
              borderRadius: '8px' // 세로 가운데 정렬을 위해 line-height 추가
            }}
          />
        </div>
        <style>
            {`
              input::placeholder {
                color: #E5E4E3;
              }
            `}
          </style>


        {/* 갤러리 섹션 */}
        <div style={{height:'30px', padding: '10px 0px',fontFamily: 'pretendard_b', fontSize: '20px', lineHeight: '150%'}}>갤러리</div>
        <div style={{ marginBottom: '24px' }}>
          <div style={{ height: '48px',fontFamily: 'pretendard', fontSize: '16px', lineHeight: '150%', color: '#777777', marginBottom:'12px'}}>9개의 사진이 3*3 배열로 작게 표현돼요.<br/>정방형 이미지를 권장해요.</div>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '10px', padding: '8px 0px' }}>
            {galleryPhotos.map((photo, index) => (
              <div
              key={index}
              style={{
                position: 'relative',
                width: '100px',
                height: '100px',
                backgroundColor: '#f5f5f5',
                border: 'none',
                display: 'flex',
                flexDirection: 'column', // 아이콘과 텍스트를 수직으로 배치
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                borderRadius: '16px',
                margin: 'auto'
              }}
              onClick={() => handleAddPhoto(index)}
            >
              {photo ? (
                <img src={photo} alt={`사진 ${index + 1}`} style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '16px',  cursor: 'pointer'}} />
              ) : (
                <>
                  <img src="/icon_add.png" alt="Add Icon" style={{ width: '24px', height: '24px', marginBottom: '2px' }} />
                  <span style={{ color: '#272B2F', fontSize: '14px',fontFamily: 'pretendard',  lineHeight: '150%' }}>사진첨부</span>
                </>
              )}
            </div>
            
            ))}
          </div>
        </div>

        {/* 회색 구분선과 "내용" 텍스트 */}
        <div style={{ backgroundColor: '#f5f5f5',height:'12px', marginLeft: '-20px', width: '150%', border:'none', marginBottom:'40px',boxSizing: 'border-box' }} />
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '52px' }}>
          <div style={{fontSize: '20px',fontFamily: 'pretendard_b',  lineHeight: '150%'}}>내용</div>
          <button onClick={handleOpenAiModal} style={{ 
            width: '142px',
            height: '32px',
            padding: '0px', 
            backgroundColor: '#EFE2B6', 
            border: 'none', 
            cursor: 'pointer', 
            textAlign: 'center', 
            borderRadius: '8px',
            display: 'flex',  // 텍스트와 아이콘을 나란히 배치
            justifyContent: 'center', 
            alignItems: 'center',
            fontFamily: 'pretendard_m',
            fontSize: '16px', 
            lineHeight: '150%'
          }}>
            원터치 AI 작성
            <img src="/icon_write.png" alt="Write Icon" style={{ width: '24px', height: '24px', marginLeft: '8px' }} />
          </button>

        </div>

        {/* 제목 입력 */}
        <div style={{height: '40px', fontFamily: 'pretendard_sb', fontSize:'16px', lineHeight: '40px'}}>제목</div>
        <div style={{ textAlign: 'center', height: '80px', padding: '8px 0px', marginBottom:'24px' }}>
          <textarea
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="제목을 작성해주세요.&#10;최대 2줄까지 가능해요."
            rows="2"
            maxLength="39" // 2줄에 해당하는 문자 길이 제한
            style={{
              resize: 'none', 
              padding: '16px',
              overflow: 'hidden',
              border: '1px solid #E5E4E3',
              width: '100%',  
              boxSizing: 'border-box',
              fontSize: '16px',
              color: '#1e1e1e',
              fontFamily: 'pretendard',
              lineHeight: '150%',
              borderRadius: '8px' // 세로 가운데 정렬을 위해 line-height 추가
            }}
          ></textarea>

          {/* 스타일 태그로 placeholder 스타일 적용 */}
          <style>
            {`
              textarea::placeholder {
                color: #E5E4E3;
              }
            `}
          </style>
        </div>


        {/* 메인 사진 */}
        <div style={{height: '40px', fontFamily: 'pretendard_sb', fontSize:'16px', lineHeight: '40px'}}>메인 사진</div>
        <div style={{ marginBottom: '32px' }}>
          <div style={{ height: '48px',fontFamily: 'pretendard', fontSize: '16px', lineHeight: '150%', color: '#777777', marginBottom:'20px'}}>제목과 함께 노출될 대표 사진이에요.<br/>보통 CEO사진을 담는 경우가 많아요.</div>
          {mainPhoto.map((photo, index) => (
              <div
                key={index}
                style={{
                  position: 'relative',
                  width: '100px',
                  height: '100px',
                  backgroundColor: '#f5f5f5',
                  border: 'none',
                  display: 'flex',
                  flexDirection: 'column', // 아이콘과 텍스트를 수직으로 배치
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  borderRadius: '16px',
                  }}
                onClick={() => handleAddMainPhoto(index)}
              >
                {photo ? (
                <img src={photo} alt={`사진 ${index + 1}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
              ) : (
                <>
                  <img src="/icon_add.png" alt="Add Icon" style={{ width: '24px', height: '24px', marginBottom: '2px' }} />
                  <span style={{ color: '#272B2F', fontSize: '14px',fontFamily: 'pretendard',  lineHeight: '150%' }}>사진첨부</span>
                </>
              )}
              </div>
            ))}
        </div>

        {isLoading && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // 배경 투명도 설정
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1000, // 다른 요소 위에 표시되도록 설정
            }}
          >
            <div
              style={{
                backgroundColor: '#fff',
                padding: '20px',
                borderRadius: '12px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // 모달 박스 쉐도우
              }}
            >
              <img
                src="/loading.gif" // 로딩 아이콘 이미지 경로
                alt="Loading"
                style={{ width: '50px', height: '50px', marginBottom: '10px' }}
              />
            </div>
          </div>
        )}


        {/* AI 작성 모달 */}
        {isAiModalOpen && (
          <div 
          className={`modal ${isAiModalClosing ? 'modal-exit' : 'modal-enter'}`}
            style={{ 
            position: 'fixed', 
            left: 0, 
            right: 0,
            bottom: 0,
            backgroundColor: '#fff', 
            padding: '0px 20px', 
            boxShadow: '0 -2px 10px rgba(0,0,0,0.2)', 
            width: '100%', // 모바일 화면 너비로 고정 320으로
            margin: '0 auto', // 중앙 정렬
            borderRadius: '10px', // 모달 중앙 배치
            zIndex: '999',
            boxSizing: 'border-box'
          }}>
            <div style={{ height: '86px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ fontSize: '20px', fontFamily: 'pretendard_b', lineHeight: '150%' }}>원터치 AI 작성</div>
              <button onClick={handleCloseAiModal} style={{ background: 'none', border: 'none', cursor: 'pointer', padding: '0' }}>
                <img src="/icon_x.png" alt="Close" style={{ width: '16px', height: '16px' }} />
              </button>
            </div>


            <div style={{height:'44px',display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '16px', fontFamily: 'pretendard_sb', lineHeight: '150%', marginBottom:'5px' }}>누구에게 보내는 카드인가요?</div>
            <div
              style={{
                display: 'grid',
                height: '110px',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: '10px',
                marginBottom: '24px',
                paddingBottom: '5px',
                placeItems: 'center'
              }}
            >
              {['친구', '엄마', '아빠', '할머니', '할아버지', '친척', '애인', '동료', '직원', '스승', '거래처', '선후배'].map((recipient) => (
                <div
                  key={recipient}
                  onClick={() => setAiRecipient(recipient)}
                  style={{
                    padding: '8px 16px',
                    borderRadius: '20px',
                    height: '24px',
                    border: 'none',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: aiRecipient === recipient ? '#EFE2B6' : '#f4f4f4',
                    color: '#000000',
                    cursor: 'pointer',
                    fontFamily: 'pretendard_m',
                    fontSize: '16px',
                    whiteSpace: 'nowrap',
                    margin: 'auto',
                    userSelect: 'none', // 각 버튼에서도 텍스트 선택 방지
                  }}
                >
                  {recipient}
                </div>
              ))}
            </div>

            <div style={{height:'44px',display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '16px', fontFamily: 'pretendard_sb', lineHeight: '150%', marginBottom:'5px' }}>어떤 분위기로 작성할까요?</div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: '10px',
                height: '110px',
                marginBottom: '24px',
                paddingBottom: '5px',
                placeItems: 'center'
              }}
            >
              {['사랑스러운',  '감성적인', '추억회고', '유머러스한', '정중한', '감동적인'].map((tone) => (
                <div
                  key={tone}
                  onClick={() => setAiTone(tone)}
                  style={{
                    padding: '8px 16px',
                    borderRadius: '20px',
                    height: '24px',
                    border: 'none',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: aiTone === tone ? '#EFE2B6' : '#f4f4f4',
                    color: '#000000',
                    cursor: 'pointer',
                    fontFamily: 'pretendard_m',
                    fontSize: '16px',
                    whiteSpace: 'nowrap',
                    margin: 'auto',
                    userSelect: 'none', // 각 버튼에서도 텍스트 선택 방지
                  }}
                >
                  {tone}
                </div>
              ))}
            </div>

            <div style={{height:'40px',display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '16px', fontFamily: 'pretendard_sb', lineHeight: '150%' }}>어떤 내용으로 작성할까요?</div>
            <div style={{ textAlign: 'center', padding: '8px 0px', marginBottom: '24px' }}>

            
            
            

            <textarea
            value={aiCustomContent}
            onChange={(e) => setAiCustomContent(e.target.value)}
            placeholder="내용을 입력해주세요."
            rows="1"
            maxLength="97" // 2줄에 해당하는 문자 길이 제한
            style={{
              resize: 'none', 
              padding: '16px',
              overflow: 'hidden',
              border: '1px solid #E5E4E3',
              width: '100%',  
              boxSizing: 'border-box',
              fontSize: '16px',
              color: '#1e1e1e',
              fontFamily: 'pretendard',
              lineHeight: '132%',
              borderRadius: '8px' // 세로 가운데 정렬을 위해 line-height 추가
            }}
          ></textarea>
            
            

            </div>
            <div style = {{height: '88px'}}>


              <button
                onClick={handleAiSubmit}
                disabled={!aiRecipient || !aiTone || !aiCustomContent}
                style={{
                  width: '100%',
                  height:'56px',
                  padding: '10px',
                  backgroundColor: '#1e1e1e',
                  color: '#EFE2B6',
                  border: 'none',
                  cursor: aiRecipient && aiTone && aiCustomContent ? 'pointer' : 'not-allowed',
                  borderRadius: '8px',
                  opacity: aiRecipient && aiTone && aiCustomContent ? '100%' : '40%',
                  fontFamily: 'pretendard_b',
                  fontSize: '16px',
                  lineHeight: '20px'
                }}
              >
                적용
              </button>




            </div>
          </div>
        )}

        {/* 본문 입력 */}
        <div style={{height: '40px', fontFamily: 'pretendard_sb', fontSize:'16px', lineHeight: '40px'}}>본문</div>
        <div style={{ textAlign: 'center', MinHeight: '80px', padding: '8px 0px', marginBottom:'52px' }}>
          <textarea
            id='content'
            value={content}
            maxLength="1000"
            onChange={(e) => {setContent(e.target.value);
              handleInput(e);
            }}
            placeholder="내용을 작성해주세요.&#10;1000자까지 가능해요."
            style={{
              resize: 'none', 
              padding: '16px',
              overflow: 'hidden',
              border: '1px solid #E5E4E3',
              width: '100%',  
              minHeight: '82px',
              boxSizing: 'border-box',
              fontSize: '16px',
              color: '#1e1e1e',
              fontFamily: 'pretendard',
              lineHeight: '150%',
              borderRadius: '8px',
              //boxSizing: 'border-box' // 패딩을 포함한 높이 계산
            }}
          ></textarea>


        </div>



        {/* 작성 완료 버튼 */}
        <div style={{ textAlign: 'center', marginBottom: '12px' }}>
          <button
            onClick={handleCardSubmit}
            disabled={!(sender && title && content && mainPhoto[0] && galleryPhotos.filter(photo => photo === null).length === 0)}
            style={{
              width: '100%', // 320으로
              height:'56px',
              padding: '10px',
              backgroundColor: '#1e1e1e',
              color: '#EFE2B6',
              border: 'none',
              cursor: sender && title && content && mainPhoto[0] && galleryPhotos.filter(photo => photo === null).length === 0 ? 'pointer' : 'not-allowed',
              borderRadius: '8px',
              opacity: sender && title && content && mainPhoto[0] && galleryPhotos.filter(photo => photo === null).length === 0 ? '100%' : '40%',
              fontFamily: 'pretendard_b',
              fontSize: '16px',
              lineHeight: '20px'
            }}
          >
            작성 완료
          </button>
        </div>

        {/* 미리보기 버튼 (텍스트 형태로 클릭 가능) */}
        <div style={{ textAlign: 'center', textDecoration: 'underline', cursor: 'pointer', height: '48px', marginBottom: '32px', color: '#666666', fontFamily: 'pretendard_m', fontSize: '16px', lineHeight: '48px'}} onClick={handleOpenPreviewModal}>
          미리보기
        </div>



      {/* 메세지 카드 완료 모달 */}
      {isCardCompleteModalOpen && (
        <div 
        className={`modal ${isCardCompleteModalClosing ? 'modal-exit' : 'modal-enter'}`}
          style={{ 
          position: 'fixed', 
          left: 0, 
          right: 0,
          top: 0,
          backgroundColor: '#fff', 
          padding: '0px 20px', 
          boxShadow: '0', 
          width: '100%', // 모바일 화면 너비로 고정 320으로
          height: '100%',
          margin: '0 auto', // 중앙 정렬
          borderRadius: '0px', // 모달 중앙 배치
          zIndex: '999',
          textAlign: 'center',
          boxSizing: 'border-box'
        }}>
        <div style={{ height: '66px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ flexGrow: 1 }}></div> {/* 빈 공간을 추가하여 오른쪽으로 밀어냄 */}
          <button onClick={handleCloseCardCompleteModal} style={{ background: 'none', border: 'none', cursor: 'pointer', padding: '0' }}>
            <img src="/icon_x.png" alt="Close" style={{ width: '16px', height: '16px' }} />
          </button>
        </div>

        <div style={{ 
  display: 'flex', 
  flexDirection: 'column', 
  justifyContent: 'center', 
  alignItems: 'flex-start', 
  height: '120px', // 전체 높이 지정
  padding: '0 0px' 
}}>
  <div style={{ 
    fontSize: '24px', 
    fontFamily: 'pretendard_b', 
    lineHeight: '40px', 
    textAlign: 'left',
    padding: '8px 0px'
  }}>
    메세지 카드 작성이<br />완료되었어요.
  </div>
  
  <div style={{ 
    color: '#777777', 
    fontSize: '16px', 
    fontFamily: 'pretendard_m', 
    lineHeight: '150%', 
    textAlign: 'left' 
  }}>
    URL을 복사하여 선물하기 화면에 입력해주세요.
  </div>
</div>



<div style={{ 
  marginBottom: '8px', 
  height: '240px', 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center' 
}}>
  <img src="/done.png" alt="Done" style={{ width: '320px', height: '216px' }} />
</div>
<div style={{ 
  display: 'flex', 
  flexDirection: 'column', 
  justifyContent: 'center', 
  alignItems: 'flex-start', 
  height: '30px', // 전체 높이 지정
  padding: '0 0px' 
}}>
  <div style={{ 
    color: '#000000', 
    fontSize: '16px', 
    fontFamily: 'pretendard_sb', 
    lineHeight: '30px', 
    textAlign: 'left' 
  }}>
    메세지 카드 URL
  </div>
</div>

<div style={{ padding: '8px 0px' }}>
  {/* URL 텍스트 박스 */}
  <div style={{ 
    border: '1px solid #E5E4E3', 
    borderRadius: '8px', 
    backgroundColor: '#f9f9f9',
    fontSize: '16px',
    fontFamily: 'pretendard_m',
    color: '#343A40', 
    display: 'flex', 
    alignItems: 'center', // 세로 가운데 정렬
    justifyContent: 'center', // 가로 가운데 정렬 (선택사항)
    wordBreak: 'break-word', 
    wordWrap: 'break-word', 
    whiteSpace: 'pre-line',
    padding: '16px', // 내부 여백 추가
    boxSizing: 'border-box' // 패딩이 전체 너비에 영향을 주지 않도록 설정
  }}>
    {window.location.origin}/card/{token}
  </div>
</div>


          {/* URL 복사하기 버튼 */}
          <div style={{ height: '56px' }}>
            <button
              onClick={() => {
                const urlToCopy = `${window.location.origin}/card/${token}`;
  
                // 임시 텍스트 영역을 생성
                const textArea = document.createElement('textarea');
                textArea.value = urlToCopy;
              
                // 텍스트 영역을 화면에 보이지 않게 추가
                document.body.appendChild(textArea);
              
                // 텍스트 영역의 내용을 선택
                textArea.select();
                textArea.setSelectionRange(0, 99999); // 모바일 장치 호환성
              
                // 선택된 내용을 클립보드에 복사
                document.execCommand('copy');
              
                // 텍스트 영역을 화면에서 제거
                document.body.removeChild(textArea);
              
                console.log('URL이 성공적으로 클립보드에 복사되었습니다!');
                alert('URL이 복사되었어요.');
              }}
              style={{
                width: '100%', //320으로
                height:'56px',
                padding: '10px',
                backgroundColor: '#1e1e1e',
                color: '#EFE2B6',
                border: 'none',
                cursor: 'pointer',
                borderRadius: '8px',
                fontFamily: 'pretendard_b',
                fontSize: '16px',
                lineHeight: '20px'
              }}
            >
              URL 복사하기
            </button>
           </div>
                    {/* URL 복사하기 버튼 */}
            <div style={{ height: '56px', marginTop:"12px" }}>
            <button
                  onClick={() => {
                    const urlToCopy = `${window.location.origin}/card/${token}`;
                    if (window.Kakao && window.Kakao.Share) {
                      window.Kakao.Share.sendDefault({
                        objectType: 'feed',
                        content: {
                          title: sender + '님으로부터 편지가 도착했습니다.',
                          description: title,
                          imageUrl: `${config.API_URL}/${uploadedMainPhotoURL}`,
                          link: {
                            mobileWebUrl: urlToCopy,
                            webUrl: urlToCopy,
                          },
                        },
                        buttons: [
                          {
                            title: '편지 보러가기',
                            link: {
                              mobileWebUrl: urlToCopy,
                              webUrl: urlToCopy,
                            },
                          },
                        ],
                      });
                    } else {
                      alert('Kakao SDK가 초기화되지 않았습니다.');
                    }
                  }}
              style={{
                width: '100%', //320으로
                height:'56px',
                padding: '10px',
                color: '#1e1e1e',
                backgroundColor: '#EFE2B6',
                border: 'none',
                cursor: 'pointer',
                borderRadius: '8px',
                fontFamily: 'pretendard_b',
                fontSize: '16px',
                lineHeight: '20px'
              }}
            >
              카카오톡 공유하기
            </button>
          </div>
        </div>
      )}


  {/* 확대 미리보기 모달 */}
  {isZoomModalOpen && (
            <div
              onClick={handleZoomClose} // 모달 클릭 시 닫기
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000,
                cursor: 'pointer'
            }}
          >
            <img src={selectedPhoto} alt="확대 사진" style={{ maxWidth: '90%', maxHeight: '90%', borderRadius: '8px' }} />
          </div>
        )}



        {isPreviewModalOpen && (
        <div 
        className={`modal ${isPreviewModalClosing ? 'modal-exit' : 'modal-enter'}`}
          style={{ 
          position: 'fixed', 
          left: 0, 
          right: 0,
          top: '0px',
          backgroundColor: '#fff', 
          padding: '0px 0px', 
          boxShadow: 'none', 
          width: '100%', // 모바일 화면 너비로 고정
          margin: '0 auto', // 중앙 정렬
          borderRadius: '0px', // 모달 중앙 배치
          maxHeight: '99vh', // 모달 최대 높이 설정
          overflowY: 'auto', // y축 스크롤 활성화
          scrollbarWidth: 'none', // Firefox에서 스크롤바 숨기기
          msOverflowStyle: 'none',  // Internet Explorer에서 스크롤바 숨기기
          boxSizing: 'border-box',
          zIndex: 999
        }}>
    <style>
    {`
        div::-webkit-scrollbar {
        display: none; /* 스크롤바 숨기기 */
        }
    `}
    </style>
    <div style={{ backgroundColor: '#fff', width: '100%', borderRadius: '10px', position: 'relative' }}>
          {/* X 버튼을 우측 상단에 고정 */}
          <div style={{ position: 'sticky', top: '0', zIndex: '10', backgroundColor:'#fff', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '32px 20px 24px 20px'}}>
  <button
    onClick={handleClosePreviewModal}
    style={{
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      padding: '0',
    }}
  >
    <img src="/icon_x.png" alt="Close" style={{ width: '20px', height: '20px' }} />
  </button>
</div>


      
      <div>
        <div style={{width: '100%', position: 'relative'}}>
        <Confetti selectedTheme={previewData} showConfetti={showConfetti} />
          {/* main_graphic_img_url */}
          {previewData.main_graphic_img_url && (
            <div style = {{}}><img
            onLoad={handleImageLoad} // 이미지 로드 이벤트
            onError={handleImageLoad} // 로드 오류 시에도 호출
            src={`${config.API_URL}/${previewData.main_graphic_img_url}`} alt="Main Graphic" style={{ width: '100%', display: 'block' }} /> </div>
          )}

          {/* 갤러리 사진 9개 표시 */}
          <div 
          style={{ 
            position: 'relative', // 자식 요소들의 절대 위치 지정 가능
            marginBottom: '32px',
            marginTop: '-0.5px',
            //padding: '65px 40px 105px 40px',
            aspectRatio: '1/1.25', // 이미지 비율 유지
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center' 
          }}
        >
          {/* 배경 이미지 */}
          <img 
            onLoad={handleImageLoad} // 이미지 로드 이벤트
            onError={handleImageLoad} // 로드 오류 시에도 호출
            src={`${config.API_URL}/${previewData.gallery_background_img_url}`} 
            alt="갤러리 배경" 
            style={{ 
              position: 'absolute', // 부모 요소에 대해 절대 위치 지정
              top: 0, 
              left: 0, 
              width: '100%', 
              objectFit: 'cover', // 이미지가 영역에 맞게 커버
              zIndex: 1, // 다른 요소들 아래로 배치
            }} 
          />

          {/* 그리드 박스 */}
          <div 
            style={{ 
              position: 'relative', // 배경 이미지 위에 오버레이
              display: 'grid', 
              gridTemplateColumns: 'repeat(3, 1fr)', 
              gap: '10px', 
              width: '280px', 
              height: '280px', 
              zIndex: 2, // 배경보다 위에 오도록 설정
            }}
          >
            {galleryPhotos.map((photo, index) => (
              <div
                key={index}
                onClick={() => photo && handleZoomOpen(photo)} 
                style={{
                  height: '72px',
                  width: '72px',
                  cursor: 'pointer',
                  backgroundColor: '#ffffff',
                  borderRadius: '6px',
                  color: '#7f7f7f',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '4px solid #ffffff',
                  fontFamily: 'sb_l',
                  margin: 'auto'
                }}
              >
                {photo ? (
                  <img src={photo} alt={`사진 ${index + 1}`} style={{ width: '72px', height: '72px', objectFit: 'cover' }} />
                ) : (
                  <span style={{ color: '#ccc' }}></span> // 업로드 안된 경우 회색 박스
                )}
              </div>
            ))}
          </div>
        </div>

        </div>

        {/* 제목 표시 */}
        <div style={{ margin: '0px', }}>
          <div style={{ textAlign: 'center',  fontSize: '20px', padding: '12px 20px', fontFamily: 'sb_m', lineHeight:'32px',wordBreak:'break-word', wordWrap:'break-word', whiteSpace:'pre-line'}}>
            {title || '제목이 없습니다.'}
          </div>
        </div>

        <div style={{ display: 'block', gridTemplateColumns: 'repeat(3, 1fr)' }}>
            {mainPhoto.map((photo, index) => (
              <div
                key={index}
                style={{
                  position: 'relative',
                  width: '100%',
                  height: '360px',
                  backgroundColor: photo ? 'transparent' : '#f0f0f0', // 업로드 안된 부분은 회색 배경
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '8px',
                  border: '1px solid #ccc',
                  boxSizing: 'border-box',
                  margin: 'auto'
                }}
              >
                {photo ? (
                  <img src={photo} alt={`사진 ${index + 1}`} style={{ width: '100%', height: '360px', objectFit: 'cover' }} />
                ) : (
                  <span style={{ color: '#ccc' }}></span> // 업로드 안된 경우 회색 박스
                )}
              </div>
            ))}
          </div>

        {/* 제목 표시 */}
        <div style={{ margin: '40px 0px 80px 0px', }}>
          <div style={{ textAlign: 'left', padding: '8px 20px', fontSize: '16px', lineHeight:'150%', fontFamily: 'pretendard',wordBreak:'break-word', wordWrap:'break-word', whiteSpace:'pre-line' }}>
            {content || '내용이 없습니다.'}
          </div>
        </div>


  
        {/* 하얀색 배경 - 메세지 남기기 및 댓글 */}
        <div style={{ backgroundColor: '#fff', padding: '0px 20px', textAlign: 'center' }}>
          {/* 메세지 남기기 */}
          <div style={{ textAlign: 'center', fontFamily: 'pretendard', fontSize: '20px', lineHeight: '33px', padding: '8px 0px'}}>메세지 남기기</div>
          <input type="text" placeholder="닉네임" style={{             resize: 'none', 
            padding: '16px',
            overflow: 'hidden',
            border: '1px solid #E5E4E3',
            width: '100%',  
            boxSizing: 'border-box',
            fontSize: '16px',
            color: '#1e1e1e',
            fontFamily: 'pretendard',
            lineHeight: '150%',
            borderRadius: '8px', 
            marginBottom: '14px', marginTop: '8px'}} />
          <textarea placeholder="메세지를 입력해 주세요.&#10;등록된 메세지는 삭제가 불가해요." style={{ 

            resize: 'none', 
            padding: '16px',
            overflow: 'hidden',
            border: '1px solid #E5E4E3',
            width: '100%',  
            boxSizing: 'border-box',
            fontSize: '16px',
            color: '#1e1e1e',
            fontFamily: 'pretendard',
            lineHeight: '150%',
            borderRadius: '8px',
            marginBottom: '8px'
            }}></textarea>
          <div style={{height:'32px', padding: '8px 0px', marginBottom: '16px'}}>
          <div style={{ padding: '4px 12px', backgroundColor: '#EFE2B6', color: '#000000', border: 'none', borderRadius: '8px', cursor: 'pointer', float: 'right', fontSize: '16px', lineHeight:'150%', fontFamily: 'pretendard_m' }}>
            등록
          </div>
          </div>
        </div>

        <hr style={{ border: '1px solid #c4c4c4', margin: '6px auto', width: '85%' }} />
        <div style = {{marginBottom: '12px'}}></div>
        {/* 댓글 카드 */}
        <div style={{padding:"8px 20px"}}>
        <div style={{ backgroundColor: '#F5F5F5', padding: '16px 20px', borderRadius: '12px', marginBottom: '40px' }}>
          <div style={{  fontSize:'14px', marginBottom:'2px', fontFamily:'pretendard_sb', lineHeight: '150%', color: '#282A2E',}}>소페셜</div>
          <div style={{ fontSize:'14px', fontFamily:'pretendard_sb', lineHeight: '150%', wordBreak:'break-word', wordWrap:'break-word', whiteSpace:'pre-line', color:'#7D7E7F' }}>소고기 감사합니다</div>
        </div>
        </div>
        <div style={{ backgroundColor: '#ffffff', marginBottom: '40px' }}>

        </div>
      </div>
    </div>
  </div>
)}









      </div>
    </div>
    </div>
    </div>
  );
};

export default MessageCardPage;
