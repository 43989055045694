// Confetti.js
import React, { useEffect, useRef } from 'react';
import config from './config';  // config.js 파일 가져오기

const Confetti = ({ selectedTheme, showConfetti }) => {
  const canvasRef = useRef(null); // canvas를 제어하기 위한 ref

  // 콘페티 효과를 실행하는 함수
  useEffect(() => {
    console.log(selectedTheme)
    console.log(showConfetti)
    if (!showConfetti || !canvasRef.current || !selectedTheme) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const confettiImage = new Image();
    
    // 테마에 따라 다른 콘페티 이미지 설정
    const confettiImageUrl = `${config.API_URL}/` + selectedTheme.theme_img_url; // API에서 가져온 이미지 경로로 설정
    confettiImage.src = confettiImageUrl;
    const parentElement = canvas.parentElement; // 부모 요소를 가져옴
    console.log(parentElement)
    const width = parentElement.clientWidth; // 부모 요소의 너비
    const height = parentElement.clientHeight; // 부모 요소의 높이
    
    let sizeMultiplier = 1
    if (selectedTheme.id !== 1)
        sizeMultiplier = 1.4

    // canvas 크기를 부모 요소의 크기와 동일하게 설정
    canvas.width = width;
    canvas.height = height;
    const confettiParticles = [];
    const particleCount = 20; // 콘페티 개수

    const createParticles = () => {
      for (let i = 0; i < particleCount; i++) {
        confettiParticles.push({
          x: Math.random() * width,
          y: Math.random() * height - height,
          speedX: Math.random() * 1 - 1.5,
          speedY: Math.random() * 1.2 + 2,
          width: 18 * sizeMultiplier,
          height: 18 * sizeMultiplier
        });
      }
    };

    const drawParticles = () => {
      ctx.clearRect(0, 0, width, height);
      confettiParticles.forEach((particle, index) => {
        particle.x += particle.speedX;
        particle.y += particle.speedY;

        if (particle.y > height) {
          confettiParticles[index] = {
            ...particle,
            x: Math.random() * width,
            y: -20,
          };
        }

        ctx.drawImage(confettiImage, particle.x, particle.y, particle.width, particle.height);
      });
    };

    const animateParticles = () => {
      drawParticles();
      if (showConfetti) {
        requestAnimationFrame(animateParticles);
      }
    };

    createParticles();
    animateParticles();
  }, [showConfetti, selectedTheme]);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: 'absolute',
        top: 0, // 부모의 맨 위로 설정
        left: 0, // 부모의 맨 왼쪽으로 설정
        width: '100%', // 부모 요소에 맞춰서 전체 너비
        pointerEvents: 'none', // 캔버스에 마우스 이벤트가 영향을 주지 않도록 설정
        zIndex: 999
      }}
    />
  );
};

export default Confetti;
